import React from "react";

export function Icon(icon, color = "black", size="14"){
  if (icon === "search"){
    return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10ZM12.5 12.5 9.536 9.535"
    />
  </svg>
  } 
  else if (icon === "menu") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        strokeWidth={1}
        className={`${color}`}
        viewBox="0 0 24 24"
      >
        <title>{"navigation-menu-3"}</title>
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.75 12.003a11.25 11.25 0 1 0 22.5 0 11.25 11.25 0 1 0-22.5 0M7.5 8.253h9M7.5 12.003h9M7.5 15.753h9"
        />
      </svg>
    );
  } else if (icon === "magic") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-0.5 -0.5 24 24"
        width={size}
        height={size}
        className={`${color}`}
      >
        <g>
          <path
            d="m6.162 5.367 2.664 0.958L10.542 4.6a0.585 0.585 0 0 1 0.958 0.431l0.067 3 2.683 1.054a0.633 0.633 0 0 1 -0.077 1.188l-3.067 0.863 -0.863 3.067a0.633 0.633 0 0 1 -1.188 0.077l-1.054 -2.683 -2.971 -0.096a0.585 0.585 0 0 1 -0.431 -0.958l1.687 -1.715 -0.958 -2.664a0.633 0.633 0 0 1 0.834 -0.795Z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="M11.74 10.963 22.042 20.125a1.342 1.342 0 0 1 0.45 0.958A1.342 1.342 0 0 1 21.083 22.521a1.342 1.342 0 0 1 -0.958 -0.479l-9.162 -10.302"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m0.479 9.104 1.917 0"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m9.104 0.479 0 1.917"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m2.396 1.438 0 1.917"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m3.354 2.396 -1.917 0"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m3.354 14.375 0 1.917"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m4.313 15.333 -1.917 0"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m14.375 3.354 1.917 0"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="m15.333 4.313 0 -1.917"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
        </g>
      </svg>
    );
  } else if (icon === "filter") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="-0.5 -0.5 24 24"
      >
        <title>{"setting-slider-horizontal"}</title>
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.104 15.333a1.917 1.917 0 0 1-3.833 0V7.667a1.917 1.917 0 0 1 3.833 0ZM11.021 11.501h11.5M.479 11.501h2.875"
        />
      </svg>
    );
  } else if (icon === "robot") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="-0.5 -0.5 24 24"
      >
        <g
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5.75.479h11.5s1.438 0 1.438 1.438v5.75s0 1.438-1.438 1.438H5.75s-1.437-.001-1.437-1.438v-5.75S4.313.479 5.75.479" />
          <path d="M6.229 4.792a1.438 1.438 0 1 0 2.875 0 1.438 1.438 0 1 0-2.875 0M13.896 4.792a1.438 1.438 0 1 0 2.875 0 1.438 1.438 0 1 0-2.875 0M10.063 11.021h2.875M10.063 12.938h2.875M14.701 14.854H8.299a3.508 3.508 0 0 0-3.431 4.245l.45 3.038a.47.47 0 0 0 .47.383H17.25a.47.47 0 0 0 .47-.383l.45-3.038a3.508 3.508 0 0 0-3.469-4.245Z" />
          <path d="m18.208 17.998 1.639-1.543a3.488 3.488 0 0 0 1.236-3.517v-.182" />
          <path d="M22.042 9.104a2.396 2.396 0 0 1-1.917 3.833 2.386 2.386 0 0 1-1.917-.958M4.792 17.998l-1.62-1.543a3.488 3.488 0 0 1-1.255-3.517v-.192" />
          <path d="M.958 9.104a2.396 2.396 0 0 0 1.917 3.833 2.386 2.386 0 0 0 1.917-.958M15.333 22.521v-4.313a.479.479 0 0 0-.479-.479H8.146a.479.479 0 0 0-.479.479v4.313" />
        </g>
      </svg>
    );
  } else if (icon === "match") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="-0.5 -0.5 24 24"
      >
        <title>{"user-network"}</title>
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m16.292 6.728 2.979-2.957M18.688 2.396a1.917 1.917 0 1 0 3.833 0 1.917 1.917 0 1 0-3.833 0M17.214 17.23l2.027 2.039M18.697 20.604a1.917 1.917 0 1 0 3.833 0 1.917 1.917 0 1 0-3.833 0M6.708 6.728 3.731 3.77M.479 2.396a1.917 1.917 0 1 0 3.833 0 1.917 1.917 0 1 0-3.833 0M5.787 17.23l-2.028 2.039M.47 20.604a1.917 1.917 0 1 0 3.833 0 1.917 1.917 0 1 0-3.833 0M15.813 11.021h2.875M18.688 11.021a1.917 1.917 0 1 0 3.833 0 1.917 1.917 0 1 0-3.833 0M7.188 11.021H4.313M.479 11.021a1.917 1.917 0 1 0 3.833 0 1.917 1.917 0 1 0-3.833 0M7.197 15.813a4.313 4.313 0 0 1 8.625 0ZM8.874 7.906a2.635 2.635 0 1 0 5.271 0 2.635 2.635 0 1 0-5.271 0"
        />
      </svg>
    );
  } else if (icon === "values") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height={size}
        width={size}
      >
        <defs></defs>
        <title>love-heart-hold</title>
        <path
          d="M4.036 11.129A5.97 5.97 0 0 0 3 14.5v4.046c0 1.513.672 2.23 2 2.954v2"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="m16 17.5 0 1.193c-.043 1.413-.713 2.106-2 2.807v2"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M6.28 13.346 2.8 9.909C-2.42 5.041 4.866-2.795 10 1.994l2 1.864 2-1.864c5.136-4.789 12.422 3.047 7.2 7.915l-8.849 8.738a.5.5 0 0 1-.7 0l-.666-.658c.1-1 .483-2.789-1.338-3.753l1.19-2.248a1.7 1.7 0 1 0-3-1.585L5 15.765"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    );
  } else if (icon === "globe") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height={size}
        width={size}
      >
        <g>
          <path
            d="m17.5 23.5-5.11-5.33a3 3 0 0 1-.57-3.5h0a3 3 0 0 1 4.84-.78l.84.83.84-.83a3 3 0 0 1 4.84.78h0a3 3 0 0 1-.57 3.5Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <g>
            <path
              d="M12 21.45A10.5 10.5 0 1 1 21.5 11"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M.66 9.17h5.55a2.72 2.72 0 0 1 1.94.83l1 1a2.73 2.73 0 0 1 .11 3.76L8 16.16A2.72 2.72 0 0 0 7.35 18v2.87"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M18.54 3.7h-5.29a2.05 2.05 0 1 0 0 4.1h.39A2.74 2.74 0 0 1 15.92 9l.25.37"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </svg>
    );
  } else if (icon === "map") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <title>{"maps"}</title>
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.791 22.292a.5.5 0 0 1-.582 0l-6.5-4.642a.5.5 0 0 1-.209-.407V2.472a.5.5 0 0 1 .791-.407L8.5 6.5l6.709-4.792a.5.5 0 0 1 .582 0l6.5 4.642a.5.5 0 0 1 .209.407v14.771a.5.5 0 0 1-.791.407L15.5 17.5ZM8.5 6.5v15.886M15.5 17.5V1.614"
        />
      </svg>
    );
  } else if (icon === "home") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <title>{"house-3"}</title>
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.5 10v12.5a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V10"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.5 13 12 1.5 23.5 13M10 15.5h4a1 1 0 0 1 1 1v7h0-6 0v-7a1 1 0 0 1 1-1Z"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 9.5a2 2 0 1 0 4 0 2 2 0 1 0-4 0"
        />
      </svg>
    );
  }
}

export function inputStyles(){
  return `mt-1 p-2 px-3 block w-full shadow-sm bg-opacity-25 rounded-md  border border-gray-200`;
}

export function labelStyles(){
  return `block text-xs bold text-gray-700 select-none hover:text-gray-900 `;
}

export function showNotification(text, error=false){
  if (error){
    console.error(text);
    alert(text)
  } else {
    alert(text);
  }
}

export function getErrorMessage(error){  
  if (error?.response?.data?.errors != undefined){
    return error.response.data.errors;
  } 
  else if (error?.response?.data != undefined) {
    return error.response.data;
  }
  else if (error?.response != undefined) {
    return error.response;
  }
  else {
    return error;
  }
 }

export function inputField(key, value, options = {}) {
  const type = options.type || "text";
  const required = options.required || false;
  return (
    <div className="col-span-6">
      <label htmlFor={key.toLowerCase()} className={`${labelStyles()}`}>
        {key}
      </label>
      <input
        type={type}
        placeholder={key}
        name={key.toLowerCase()}
        id={key.toLowerCase()}
        defaultValue={value}
        className={`${inputStyles()}`}
        required={required}
      />
    </div>
  );
};

export function inputTexts(array, site, options = {}){
  const typeOfInputField = options.field || "text";
  const placeholder = options.placeholder || "";
  const css = options.css || "col-span-1"
  return array
    .map((key) => ({ [key]: site[key] }))
    .map((item, index) => {
      let label = Object.keys(item)[0]?.replaceAll("_", " ");
      let key = Object.keys(item)[0].replaceAll(" ", "");
      let value = Object.values(item)[0];
      if (typeOfInputField === "textarea") {
        return (
          <div className={`col-span-2`} key={`${key}_${index}`}>
            <label
              htmlFor={Object.keys(item)[0]}
              className={`${labelStyles()} cap`}
            >
              {label}
            </label>
            <textarea
              type={"text"}
              id={key}
              name={key}
              defaultValue={value}
              className={`${inputStyles()} mb-3`}
              placeholder={placeholder}
              style={{ minHeight: "200px" }}
            />
          </div>
        );
      } else {
        return (
          <div className={`${css}`} key={`${key}_${index}`}>
            <label htmlFor={key} className={`${labelStyles()} cap`}>
              {label}
            </label>
            <input
              type={"text"}
              id={key}
              name={key}
              defaultValue={value}
              className={inputStyles()}
              placeholder={placeholder}
            />
          </div>
        );
      }
    });
};

export function loadingPulse(isLoading) {
  if (isLoading === true) {
    return (
      <div className="p-6 text-center">
        <div className="simple-spinner simple-spinner-smaller simple-spinner-dark">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export function convertFilterPageLocationToSlug(text) {
  let slug = text
    .toLowerCase()
    .replace(/ /g, "-")
    .replaceAll("å", "a")
    .replaceAll("ä", "a")
    .replaceAll("ö", "o")
    .replace(/2019/g, "")
    .replace(/2020/g, "")
    .replace(/2021/g, "")
    .replace(/2022/g, "")
    .replace(/2023/g, "")
    .replace(/2024/g, "")
    .replace(/2025/g, "")
    .replace(/2026/g, "")
    .replace(/2027/g, "")
    .replace(/2028/g, "")
    .replace(/2029/g, "")
    .replace(/2030/g, "")
    .replaceAll("year", "")
    .replaceAll("city_name", "location_slug")
    .replaceAll("state_name", "location_slug")
    .replaceAll("country_name", "location_slug")
    .replaceAll("-{{}}", "")
    .replaceAll("{{}}", "");

  // Remove a dash at the beginning, if present
  if (slug.startsWith("-")) {
    slug = slug.substring(1);
  }
  
  return slug;
}

export const getIdFromURL = (indexOfSpecific) => {
  const urlParts = window.location.href.split("/");
  const siteIndex = urlParts.indexOf(indexOfSpecific);
  return siteIndex !== -1 && siteIndex + 1 < urlParts.length
    ? parseInt(urlParts[siteIndex + 1])
    : null;
};