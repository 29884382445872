import React, { Component } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

export function sizeOfLogoBasedOnCharchters ( site_name ) {
  const site_length = site_name?.length;
  console.log(`site_name: ${site_length}`);
  if (site_length > 26) {
    return "text-md mb-1 pb-0 md:pb-.5";
  } 
  
  else if (site_length > 22) {
    return "text-lg";
  } 
  
  else if (site_length > 18) {
    return "text-xl";
  } 
  
  else if (site_length > 13) {
    return "text-2xl";
  } 
  
  else if (site_length > 8) {
    return "text-3xl";
  } 
  
  else if (site_length > 4) {
    return "text-4xl";
  } 
  
  else {
    return "text-5xl";
  }
}

export class Logo extends Component {
  render() {
    const site = this.props.site;
    const language = this.props.language;
    const link = this.props.link;
    
    return (
      <div className="Logo">
        {(site != undefined && (site?.logo !=  undefined && site?.logo != "" || site?.logo != null) && (
            <div className="flex items-center pt-2">
              <Link to={link}>
                <img
                  src={site?.logo}
                  alt={`Logo of ${site?.name}`}
                  className="block Logo"
                  loading="lazy"
                />
              </Link>
            </div>
          ))}
        {site?.logo == "" && (
          <>
            <div>
              {site?.name && (
                <Link
                  to={link}
                  className={`block logo font-semibold mb-1.5 truncate max-w-sm ${sizeOfLogoBasedOnCharchters(
                    site?.name
                  )}`}
                >
                  {site?.name}
                </Link>
              )}
            </div>

            <div className="relative">
              <div className={`mini-corner bg-${site.primary_color}-200`}></div>
              <div
                className={`z-1 bg-${site.primary_color}-200 text-${site.primary_color}-600 font-bold text-xs rounded-md	px-2 py-1 inline-flex`}
              >
                <div className="cap">
                  <span>{language?.compare}</span>{" "}
                  <span>{site.sites_listings_count}</span>+{" "}
                  <span>{language?.hotels}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Logo